<template>
  <div class="container">
    <div class="footer">
        <ul> 
            <li>
                <img src="../../../../public/images/home/icon_footer_01.png" alt="海量商品">
                <div class="intro">
                    <span class="title">海量商品，急速交易</span>
                    <span class="desc">海量商品，极速交易，安全省心</span>
                </div>
            </li>
            <li>
                <img src="../../../../public/images/home/icon_footer_02.png" alt="海量商品">
                <div class="intro">
                    <span class="title">专业客服</span>
                    <span class="desc">专业客服，为您服务</span>
                </div>
            </li>
            <li>
                <img src="../../../../public/images/home/icon_footer_03.png" alt="海量商品">
                <div class="intro">
                    <span class="title">急速回收</span>
                    <span class="desc">专业回收团队</span>
                </div>
            </li>
            <li>
                <img src="../../../../public/images/home/icon_footer_04.png" alt="海量商品">
                <div class="intro">
                    <span class="title">官方交易，安全可靠</span>
                    <span class="desc">签署正规电子合同</span>
                </div>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container{
    margin-top: 30px;
    width: calc(100vw);
    min-width: 1440px;
    height: 100px;
    // height:calc(100vh);

    .footer{
        margin: 0 auto;
        width: 1200px;
        height: 100px;
        background: #FFFFFF;
        border-radius: 4px;
        ul{
            height: 100px;
            display: flex;
            align-items: center;
            li{
                width: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: default;
                img{
                    width: 40px;
                    height: 40px;
                    border: 0;
                    vertical-align: middle;
                }
                .intro{
                    display: flex;
                    flex-direction: column;
                    margin-left: 20px;
                    .title{
                        font-size: 16px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        color: #000000;
                    }
                    .desc{
                        margin-top: 10px;
                        font-size: 12px;
                        font-family: MicrosoftYaHei;
                        color: #888888;
                    }
                }

            }
        }
    }
}

</style>