<template>
    <div class="container">
         <div class="content">
          <div class="content-left">
            <div class="content-top-menu">
                <ul>
                    <li @click="clickMenuHndler(index)" v-for="(item,index) in menuData" :key="index">
                        <p class="menu-title" :style="{color:currentMenuIndex===index?'#F02233':''}">{{item}}</p>
                        <!-- <div class="menu-silder" :style="{display:currentMenuIndex===0?'block':''}"></div> -->
                    </li>
                    <!-- <li @click="clickMenuHndler(1)">
                        <p class="menu-title" :style="{color:currentMenuIndex===1?'#F02233':''}">手游攻略</p>
                    </li>
                    <li @click="clickMenuHndler(2)">
                        <p class="menu-title" :style="{color:currentMenuIndex===2?'#F02233':''}">端游攻略</p>
                    </li>
                    <li @click="clickMenuHndler(3)">
                        <p class="menu-title" :style="{color:currentMenuIndex===3?'#F02233':''}">行业资讯</p>
                    </li> -->
                    <div class="menu-silder" :style="{width:menuSliderWidthStyle,left:menuSliderLeftStyle}"></div>
                </ul>
            </div>
            <div class="content-bottom-list">
                <news-list-content></news-list-content>
            </div>
            <div class="content-bottom-pagination">
                <div class="block">
                    <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="currentPageSize"
                    layout="total, prev, pager, next"
                    :total="totalCount">
                    </el-pagination>
                </div>
            </div>
          </div>
          <div class="content-right">
            <recommend-list></recommend-list>
          </div>
         </div>
         <content-footer></content-footer>
    </div>
  </template>
  
  <script>
  import Header from '../home/header.vue';
  import NewsListContent from './components/news-list.vue';
  import RecommendList from './components/recommend-list.vue';
  import ContentFooter from '../home/component/content-footer.vue';
  export default {
    name:'GamesNews',
    components:{
        Header,
        NewsListContent,
        RecommendList,
        ContentFooter
    },
    data(){
        return{
            menuData:["全部","手游攻略","端游攻略","行业资讯"],
            currentMenuIndex:0, //当前菜单索引
            currentPage:1, //当前页
            currentPageSize:100,//一页显示的数目
            totalCount:1000 //总条数
        }
    },
    methods:{
        clickMenuHndler(index){
            //console.log('index==='+index);
            this.currentMenuIndex = index;
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        }
    },
    computed:{
        menuSliderWidthStyle(){
            return this.menuData[this.currentMenuIndex].length*15+'px';
        },
        menuSliderLeftStyle(){
            let tempLength = 0;
            this.menuData.forEach((item,index)=>{
                if(index<this.currentMenuIndex){
                    tempLength += this.menuData[index].length*15;
                }
            });
            let ttempLength = this.currentMenuIndex===0?0:tempLength;
            return this.currentMenuIndex*50 + ttempLength + 30 +'px';
        }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .container{
      background:#F5F5F5;
      width: calc(100vw);
      min-width: 1440px;
      .content{
          margin: 0 auto;
          width: 1200px;
          display: flex;
          justify-content: space-between;
          .content-left{
            margin-top: 20px;
            width: 860px;
            min-height: 890px;
            background-color: #FFFFFF;
            border-radius: 6px;
            .content-top-menu{
                width:860px;
                height: 52px;
                background: #FFFFFF;
                box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
                border-radius: 6px 6px 0px 0px;
                ul{
                    height: 52px;
                    width:860px;
                    padding: 20px 20px 20px 30px;
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    position: relative;
                    li{
                        margin-right: 50px;
                        height: 52px;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .menu-title{
                            height: 20px;
                            font-family: MicrosoftYaHei;
                            font-size: 15px;
                            color: #555555;
                            line-height: 20px;
                            text-align: left;
                            font-style: normal;
                            cursor: pointer;
                        }
                        &:hover .menu-title{
                            color: #F02233;
                        }
                    }
                    .menu-silder{
                        position:absolute;
                        left: 0px;
                        bottom: 0px;
                        width: 30px;
                        height: 2px;
                        background: #F02233;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
            .content-bottom-list{
                width: 820px;
                padding: 0px 20px;
                
            }
            .content-bottom-pagination{
                width: 820px;
                height: 68px;
                margin-top: 20px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

            }
          }
          .content-right{
            margin-top: 20px;
            border-radius: 6px;
            width:320px;
            background: #FFFFFF;
            height: calc(115vh);
          }
      }
  }

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #F02233;   // 进行修改选中项背景和字体
    color: #fff;
}
/* 当鼠标划过页码时改变颜色 */
/deep/.el-pagination.is-background .el-pager li:hover {
    color:#F02233 ; /* 你想要设置的颜色 默认#409EFF */
}
  
  </style>